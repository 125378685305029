@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}
