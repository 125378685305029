body::-webkit-scrollbar,
.MuiTableContainer-root::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track,
.MuiTableContainer-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #fabf59;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover,
.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background: #555;
}
